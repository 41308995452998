import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import HandsOnMobile from '../assets/img/handsonmobile.png';
import HandsOnMobile1 from '../assets/img/handsonmobile1.png';
import useStyles from '../styles/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { FONT_FAMILY } from 'common/sharedFunctions';

const DownloadApp = () => {
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { t } = useTranslation();

  return (
    <Box className={classes.aboutUsContainer} style={{marginBottom: -15}}>
      <Grid container spacing={5} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <img src={HandsOnMobile} alt="App Download" className={classes.largeImage} style={{width:'60%',marginLeft:50}} />
          <Typography variant="h6" style={{textAlign:'center'}}  fontFamily={FONT_FAMILY} className={classes.title}>
            {t('driver_app')}
          </Typography>
          <img src={HandsOnMobile1} alt="App Download" className={classes.largeImage} style={{width:'60%',marginLeft:50}}/>
          <Typography variant="h6" style={{textAlign:'center'}}  fontFamily={FONT_FAMILY} className={classes.title}>
            {t('customer_app')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* App Download Section */}
          <Typography variant="h5" fontWeight={700} fontFamily={FONT_FAMILY} className={classes.title} >
            {t('mobile_apps_on_store')}
          </Typography>
          {/* <Typography fontFamily={FONT_FAMILY} className={classes.aboutUsSubtitle}>
            {t('app_store_deception1')}
          </Typography>
           */}
          {/* Explanation Section */}
          <Box mt={5}>
            <Typography variant="h6" fontWeight={600} fontFamily={FONT_FAMILY}>
              How to Use the App
            </Typography>

            {/* Sign Up / Login Details */}
            <Typography fontFamily={FONT_FAMILY} className={classes.aboutUsSubtitle}>
              <strong>Sign-Up and Login Options:</strong> You can easily sign up or log in using:
              <ul>
                <li>Phone Number with SMS verification</li>
                <li>Google Account</li>
                <li>Email and Password</li>
                <li>Apple ID for iOS users</li>
              </ul>
              If you forget your password, you can use the "Forgot Password" feature to reset it by sending a reset email.
            </Typography>

            {/* Customer and Driver Roles */}
            <Typography fontFamily={FONT_FAMILY} className={classes.aboutUsSubtitle}>
              <strong>Choosing Your Role:</strong> When signing up, select either:
              <ul>
                <li><strong>Customer:</strong> Access quick and reliable delivery or ride services immediately after login.</li>
                <li><strong>Driver:</strong> Upload your necessary documents (e.g., license, vehicle registration) to get verified and start accepting bookings.</li>
              </ul>
            </Typography>

            {/* Additional Info */}
            <Typography fontFamily={FONT_FAMILY} className={classes.aboutUsSubtitle}>
              <strong>Platform Use:</strong> Whether on the app or website, after logging in, you can manage your bookings, track deliveries, and access all services easily.
            </Typography>

            {settings && settings.AppleStoreLink ? (
            <a href={settings.AppleStoreLink}>
              <img src={require("../assets/img/appstore.webp").default} alt="Apple Store Link" style={{height:'20%',width:'50%'}}/>
            </a>
          ) : null}
          <span style={{ marginRight: '5px' }}></span>
          {settings && settings.PlayStoreLink ? (
            <a href={settings.PlayStoreLink}>
              <img src={require("../assets/img/playstore.webp").default} alt="Playstore Link" style={{height:'20%',width:'50%'}}/>
            </a>
          ) : null}

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadApp;
