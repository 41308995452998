import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { calcEst, showEst, optionsRequired, FONT_FAMILY } from '../common/sharedFunctions';
import { colors as col } from "../components/Theme/WebTheme";
import useStyles from '../styles/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Import the images
import slide1 from '../assets/img/slide1.jpg';
import slide2 from '../assets/img/slide2.jpg';
import slide3 from '../assets/img/slide3.jpg';
import slide4 from '../assets/img/slide4.jpg';
import slide5 from '../assets/img/slide5.jpg';

// Array to hold slide data
const slides = [
  {
    image: slide2,
    title: 'Rifan transportation and adaelivery',
    description: 'Convenient service that allows customers to quickly schedule rides through a user-friendly platform. Whether for personal or business travel, this service simplifies the process with easy online booking, ensuring reliable and timely transportation. With just a few clicks, users can choose their vehicle, set their destination, and confirm their ride, making travel hassle-free and efficient.',
    buttonText: 'Get Started',
  },
  {
    image: slide1,
    title: 'Small package and parcel transportation or Delivery',
    description: 'Provide quick and efficient transportation solutions for urgent parcels and packages. This service ensures timely delivery, offering speed and reliability to meet tight deadlines, whether for personal or business needs. With a focus on convenience, fast delivery services streamline the process from booking to doorstep delivery, ensuring packages arrive safely and on time.',
    buttonText: 'Get Started',
  },
  {
    image: slide3,
    title: 'cargo transportation or Delivery',
    description: 'Specialized service designed for transporting goods that require strict temperature regulation. This service ensures that perishable items, such as food, pharmaceuticals, and other temperature-sensitive products, are transported under optimal conditions, using refrigerated vehicles to maintain the required temperature throughout the journey. It guarantees the safe and reliable delivery of these goods while preserving their quality and integrity.',
    buttonText: 'Get Started',
  },
  {
    image: slide4,
    title: 'Vehicle Breakdown Assistance',
    description: 'This is a service we offer to help drivers book a car carrier truck to transport their vehicle in case of a breakdown. This ensures a quick and reliable solution for moving disabled vehicles to the desired location or repair facility, providing peace of mind and minimizing inconvenience for the driver.',
    buttonText: 'Get Started',
  },
  {
    image: slide5,
    title: 'Dinah transportation',
    description: 'This is a service we offer to help drivers book a car carrier truck to transport their vehicle in case of a breakdown. This ensures a quick and reliable solution for moving disabled vehicles to the desired location or repair facility, providing peace of mind and minimizing inconvenience for the driver.',
    buttonText: 'Get Started',
  }
];

const Hero = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0); // To track the current slide
  const auth = useSelector(state => state.auth);

  // Automatically cycle through the slides every 7 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 7000);
    
    return () => clearInterval(slideInterval);
  }, []);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);

  // Handle Next Slide
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  // Handle Previous Slide
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <Box
      className={classes.heroBox}
      sx={{
        backgroundImage: `url(${slides[currentSlide].image})`,
        backgroundSize: 'contain',
        height:'95vh',
        backgroundPosition: 'center',
        transition: 'background-image 1.5s ease-in-out', // Add smooth transition for background image
      }}
      style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
    >
      <Grid container spacing={3} className={classes.gridContainer} sx={{ alignItems: 'center' }}>
        
        {/* Previous Arrow */}
        <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' } }}>
          <IconButton onClick={handlePrevSlide} style={{ color: 'white' }}>
            <ArrowBackIosIcon fontSize="large" />
          </IconButton>
        </Grid>
        
        <Grid item xs={12} md={6} style={{background:col.TRANSPARENT}}>
          <Typography variant="h4" fontWeight={500} className={classes.titleMain} style={{ color: col.BLUE, fontFamily: FONT_FAMILY }}>
            {slides[currentSlide].title}
          </Typography>
          {/* <Typography variant="h6" className={classes.subtitleMain} style={{ color: col.WHITE, fontFamily: FONT_FAMILY }}>
            {slides[currentSlide].description}
          </Typography> */}
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px', fontFamily: FONT_FAMILY,marginTop:10 }}
            onClick={(e) => {
              e.preventDefault();
              if (loggedIn) {
                navigate(((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings');
              } else {
                navigate('/login');
              }
            }}
          >
            {role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info') : slides[currentSlide].buttonText}
          </Button>
        </Grid>
        
        {/* Next Arrow */}
        <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'right' }}>
          <IconButton onClick={handleNextSlide} style={{ color: 'white' }}>
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>

      {/* Responsive Controls for small screens */}
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', padding: '0 20px' }}>
        <IconButton onClick={handlePrevSlide} style={{ color: 'white' }}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={handleNextSlide} style={{ color: 'white' }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Hero;
