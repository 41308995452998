import React from "react";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax";
import {useTranslation} from "react-i18next";

const dashboardRoutes = [];

const items = [
    {
        translateKey: 'our_mission'
    },
    {
        translateKey: 'urgent_parcels'
    },
    {
        translateKey: 'breakdown_vehicles'
    },
    {
        translateKey: 'temperature_controlled'
    },
    {
        translateKey: 'cargo_goods'
    },
    {
        translateKey: 'moving_services'
    },
    {
        translateKey: 'payment'
    }
    ,
    {
        translateKey: 'online_service_management'
    },
    {
        translateKey: 'satisfaction_guaranteed'
    }

]
const services = [
    {
        translateKey: 'aboutUs_description_our_services_1',
        href: ''
    },
    {
        translateKey: 'aboutUs_description_our_services_2',
        href: ''
    },
    {
        translateKey: 'aboutUs_description_our_services_3',
        href: ''

    },
    {
        translateKey: 'aboutUs_description_our_services_4',
        href: ''

    },
    {
        translateKey: 'aboutUs_description_our_services_5',
        href: ''

    },


]

const weServe = [
    {
        translateKey: 'aboutUs_description_benefit_withUs_1',
        href: ''
    },
    {
        translateKey: 'aboutUs_description_benefit_withUs_2',
        href: ''
    },
    {
        translateKey: 'aboutUs_description_benefit_withUs_3',
        href: ''
    },
    {
        translateKey: 'aboutUs_description_benefit_withUs_4',
        href: ''
    }

]
export default function AboutUs(props) {
    const {t} = useTranslation();
    const {...rest} = props;

    return (
        <div style={{margin: '-8px'}} className="bg-blue-950">
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/image_leaving.jpg").default}/>
            <div className="relative z-10 bg-white rounded-2xl shadow-2xl w-11/12 mx-auto">
                <div className={`sm:px-10 px-4 py-10 flex flex-col gap-10 tracking-wide leading-loose`}>
                    {/*<div className="relative text-white z-10 rounded-2xl shadow-2xl w-9/12 mx-auto">*/}
                    {/*    <div className={"flex flex-col gap-4 justify-center items-center"}>*/}
                    {/*        <span className={`text-5xl font-extrabold`}>About Us</span>*/}
                    {/*        <span className={`text-4xl`}>We Just Love What we Do,</span>*/}
                    {/*        <span className={`text-3xl text-center`}>10 Years of Pick Up and Drop Off Passengers To and From Salt Lake City International Airport (SLC)</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={"flex justify-center items-center"}>
                        <span className={`text-blue-950 text-5xl`}>{t('aboutUs')}</span>
                    </div>
                    <div className={"grid sm:grid-cols-2 grid-cols-1 sm:gap-10 text-black"}>
                        <div className="w-full">
                            <img className="w-full rounded-xl" src={require("assets/img/aboutus-image.jpg").default}
                                 alt={'about-us'}/>
                        </div>
                        <div className="flex flex-col gap-4">
                            <span className={`text-blue-950 text-4xl`}>{t('aboutUs_title_who_we_are')}</span>
                            <div className="self-center text-lg">
                              {t('aboutUs_description_who_we_are')}
                                                            </div>
                        </div>
                    </div>

                    <div className={"flex flex-col w-full text-black gap-10"}>
                        {items.map((item, index) => {
                            return <div key={index} className="flex flex-col w-full">
                                <div
                                    className="text-blue-950 text-4xl sm:text-left text-center">{t(`aboutUs_title_${item.translateKey}`)}</div>
                                <div>{t(`aboutUs_description_${item.translateKey}`)}</div>
                            </div>
                        })}
                    </div>
                    <div className={"flex flex-col w-full text-black gap-10"}>
                        <div className="flex flex-col w-full">
                            <div
                                className="text-blue-950 text-4xl sm:text-left text-center">{t(`aboutUs_title_our_services`)}</div>
                            <ol className="text-xl">
                                {services.map((service,key) => {
                                    return <li key={key}>
                                            {t(service.translateKey)}
                                    
                                    </li>
                                })}
                            </ol>
                        </div>
                    </div>
                    <div className={"flex flex-col w-full text-black gap-10"}>
                        <div className="flex flex-col w-full">
                            <div
                                className="text-blue-950 text-4xl sm:text-left text-center">{t(`aboutUs_title_benefit_withUs`)}</div>
                            <ol className="text-xl gap-5">
                                {weServe.map((serve,key) => {
                                    return <li key={key}>
                                            {t(serve.translateKey)}
                                       
                                    </li>
                                })}
                            </ol>
                        </div>
                    </div>
                    {/*<br/>*/}

                    {/*<h2 style={{*/}
                    {/*    textAlign: isRTL === 'rtl' ? 'right' : 'left',*/}
                    {/*    position: "relative",*/}
                    {/*    marginTop: "30px",*/}
                    {/*    minHeight: "32px",*/}
                    {/*    color: "#383838",*/}
                    {/*    textDecoration: "none",*/}
                    {/*    [isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",*/}
                    {/*    wordBreak: "break-word"*/}
                    {/*}}>{t('about_us')}</h2>*/}
                    {/*<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{t('about_us_content2')}</p>*/}
                    {/*<p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{t('about_us_content1')}</p>*/}
                    {/*<br/>*/}
                </div>
            </div>

            <Footer className="text-white" poweredByClassName={'text-white'} companyWebsiteClassName={'!text-white'}/>
        </div>
    );
}
